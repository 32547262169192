/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { createSelector } from 'reselect';
import { isLocationSelected } from '../reducers/chart.reducers';
import { Market } from 'src/constants/contract';

export const chartSelector = state => state.chart;

export const paperChartSelector = createSelector(chartSelector, state => state[Market.Paper]);
export const physicalChartSelector = createSelector(chartSelector, state => state[Market.Physical]);

export const chartProductId = createSelector(
	chartSelector,
	state => state[Market.Physical].product?._key
);

export const chartPriceType = market =>
	createSelector(chartSelector, state => state[market].priceType);

export const chartGradeId = createSelector(
	chartSelector,
	state => state[Market.Physical].grade?._key
);

export const chartIncoId = createSelector(
	chartSelector,
	state => state[Market.Physical].inco?._key
);

export const chartLocation = createSelector(
	chartSelector,
	state => state[Market.Physical].location
);

export const chartShipment = market =>
	createSelector(chartSelector, state => state[market].shipment);

export const chartPKPGWasShipmentSet = market =>
	createSelector(chartSelector, state => state[market].wasPKPGShipmentSet);

export const chartCargoType = createSelector(
	chartSelector,
	state => state[Market.Physical].cargoType
);

export const chartPaperPreset = createSelector(
	chartSelector,
	state => state[Market.Paper].paperPreset
);

export const areFiltersValid = (filters, market) => {
	if (market === Market.Physical) {
		return !!(
			filters.product?._key &&
			filters.inco?._key &&
			filters.priceType &&
			isLocationSelected(filters.location)
		);
	}

	return !!filters.priceType;
};
