/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { QuoteType } from 'src/constants/contract';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { getNumberOfMonths } from 'src/_helpers/date';

const VolumeField = ({ path, t }) => {
	const { orderTypeValue, firstLegMonthValue, secondLegMonthValue } = useWatchPaperFields(path);

	const firstLegMonths = firstLegMonthValue?.startDate
		? getNumberOfMonths(firstLegMonthValue.startDate, firstLegMonthValue.endDate)
		: 1;
	const secondLegMonths = secondLegMonthValue?.startDate
		? getNumberOfMonths(secondLegMonthValue.startDate, secondLegMonthValue.endDate)
		: 1;

	const label = firstLegMonths > 1 || secondLegMonths > 1 ? t('total_quantity') : t('quantity');

	return (
		<Controller
			mandatory={orderTypeValue === QuoteType.Firm}
			name={toPath(path, 'volume')}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					required={orderTypeValue === QuoteType.Firm}
					error={!!error}
					data-test={field.name}
					id={field.name}
					label={label}
					fixedDecimalScale={false}
					parameters={t('price_unit_short_metric_ton')}
					isAllowed={value => value.floatValue !== 0}
					{...field}
				/>
			)}
		/>
	);
};

export const volumeField = (path, t) => <VolumeField path={path} t={t} />;
