/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { arrayOfNElements } from 'src/_helpers/array';

export const getMonthObject = date => ({
	date,
	value: date.format('YYYY-MM'),
	label: date.format('MMM YYYY'),
});

export const parseDate = date => (date ? getMonthObject(moment(date)) : null);

export const generate12MonthsFromDate = (startDate = null) => {
	return arrayOfNElements(12).map(num => {
		const date = moment(startDate).utc(0).add(num, 'M');

		return getMonthObject(date);
	});
};

export const getPrevYear = startDate => {
	return moment(startDate).subtract(1, 'y');
};

export const getNextYear = startDate => {
	return moment(startDate).add(1, 'y');
};

export const getYearsFromArray = months => {
	const years = months.reduce((list, item) => {
		const year = item.date.format('Y');
		if (!list.includes(year)) {
			list.push(year);
		}

		return list;
	}, []);

	return years.join('/');
};

export const isOutOfRange = (month, cutOffDate) =>
	!!cutOffDate && moment(cutOffDate).isBefore(month, 'month');

export const isDateInThePast = momentDate => {
	const currentMonthStart = moment().startOf('month');

	return momentDate.isBefore(currentMonthStart);
};

export const getInitialMonthDisplayed = (startDate = null) => {
	const now = Date.now();

	if (!startDate) {
		return now;
	}

	const currentMonthStart = moment().utc(0).startOf('month');
	const startDateMonthStart = moment(startDate).utc(0).startOf('month');

	const diffInYears = startDateMonthStart.diff(currentMonthStart, 'Y');

	if (diffInYears === 0) {
		return now;
	}

	return parseInt(currentMonthStart.add(diffInYears, 'Y').format('x'));
};

const QuartersCount = 12;

const quarters = ['JFM', 'AMJ', 'JAS', 'OND'];

export const getQuarterLabel = date => {
	const quarter = date.quarter() - 1;
	const year = moment(date).clone().year().toString().slice(-2);

	return `${quarters[quarter]} ${year}`;
};

export const generateQuarters = () => {
	const now = moment();

	let quarterStart = moment().utc().startOf('year');
	const currentYear = quarterStart.year();

	let startYear = currentYear;

	let results = [];

	for (let i = 0; i < QuartersCount; i++) {
		const quarterIndex = i % 4;
		const year = startYear + Math.floor(i / 4);
		const quarter = quarters[quarterIndex];

		if (i > 0) {
			quarterStart = quarterStart.clone().add(1, 'quarter').startOf('month');
		}

		const quarterEnd = quarterStart.clone().add(1, 'quarter').subtract(1, 'days').endOf('day');

		const outdated = now.isAfter(quarterEnd);

		const quarterLabel = `${quarter} ${year.toString().slice(-2)}`;

		results.push({
			label: quarterLabel,
			value: {
				quarterStart,
				quarterEnd,
			},
			outdated,
		});
	}

	return results;
};

export const isMiddleQuarter = (quarterRangeStart, quarterRangeEnd, quarter) => {
	const rangeStartingQuarterEnd = quarterRangeStart
		.clone()
		.add(1, 'quarter')
		.subtract(1, 'days')
		.endOf('day');

	const rangeEndingQuarterStart = quarterRangeEnd
		.clone()
		.subtract(1, 'quarter')
		.add(1, 'days')
		.startOf('day');

	return (
		quarter.value.quarterStart.isAfter(rangeStartingQuarterEnd) &&
		quarter.value.quarterEnd.isBefore(rangeEndingQuarterStart)
	);
};
export const isReverseQuarterSelection = (firstSelectedQuarter, secondSelectedQuarter) => {
	if (secondSelectedQuarter.value.quarterEnd.isBefore(firstSelectedQuarter)) {
		return true;
	}

	return false;
};

export const getNextQuarter = initialDate => {
	const date = moment(initialDate).format('MM-DD');
	const thisYear = +moment().format('YY');
	const nextYear = thisYear + 1;

	const periods = [
		{
			start: `01-01`,
			end: `03-15`,
			quarter: `${quarters[1]} ${thisYear}`,
		},
		{
			start: `03-16`,
			end: `06-15`,
			quarter: `${quarters[2]} ${thisYear}`,
		},
		{
			start: `06-16`,
			end: `09-15`,
			quarter: `${quarters[3]} ${thisYear}`,
		},
		{
			start: `09-16`,
			end: `12-15`,
			quarter: `${quarters[0]} ${nextYear}`,
		},
		{
			start: `12-16`,
			end: `12-31`,
			quarter: `${quarters[1]} ${nextYear}`,
		},
	];

	const list = generateQuarters();

	const currentPeriod = periods.find(({ start, end }) => date >= start && date <= end);

	const nextQuarter = list.find(item => item.label === currentPeriod.quarter).value;

	return {
		startDate: nextQuarter.quarterStart.toISOString(),
		endDate: nextQuarter.quarterEnd.toISOString(),
		format: 'quarters',
		continuousPeriod: null,
	};
};
